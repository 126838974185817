import request from "@/utils/request"

//创建订阅
export const createSubscription = data => {
  return request({
    method: 'POST',
    url: '/subscriptions',
    data
  })
}
//列出订阅
export const subscriptionList = () => {
  return request({
    url: '/subscriptions'
  })
}
//查询订阅
export const querySubscription = subscriptionId => {
  return request({
    url: `/subscriptions/${subscriptionId}`
  })
}
//取消订阅
export const cancelSubscription = subscriptionId => {
  return request({
    method: 'DELETE',
    url: `/subscriptions/${subscriptionId}`
  })
}
//列出扣费记录
export const record = subscriptionId => {
  return request({
    url: `/subscriptions/${subscriptionId}/payments`
  })
}
//微信回调接口
export const wxhook = () => {
  return request({
    method: 'POST',
    url: '/webhook/wxpay'
  })
}
// export const signed = () => {
//   return fetch("https://api.mch.weixin.qq.com/pay/contractorder", {
//     method: "POST",
//     // headers: { "Content-Type":"application/json" },
//     // mode: "cors",
//     body: {
//       appid: 'wxe29a89495a80418c',
//       mch_id: '1624978504',
//       contract_mchid: '1624978504',
//       contract_appid: 'wxe29a89495a80418c',
//       out_trade_no: 'asdasd12324324',
//       nonce_str: 'asdasd12324324',
//       body: 'Halo-Studio使用权益',
//       notify_url: 'https://www.prometh.xyz',
//       total_fee: 30,
//       spbill_create_ip: ''
//     }
//   })
// }