<template>
  <div>
    <div class='halo-studio'>
      <h1 class="title">Halo-Studio</h1>
      <div class="card">
        <h4 class="card-title">Halo-Studio</h4>
        <div class="card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin</div>
        <div>￥3000  用户/月</div>
        <div class="card-button" @click="payWindow">
          订阅
        </div>
      </div>
      <el-table
        :data="tableData"
        border
        class="table">
        <el-table-column
          prop="date"
          align="center"
          label="日期">
        </el-table-column>
        <el-table-column
          prop="name"
          align="center"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="address"
          align="center"
          label="地址">
        </el-table-column>
      </el-table>
      <el-dialog
        title="订阅 Halo-Studio"
        :center="true"
        :visible.sync="dialogVisible"
        top="30vh"
        width="30%">
        <div>
          <div>
            <el-radio-group v-model="radio1">
              <el-radio-button label="￥1000">1月 <br/><span class="money">￥1000</span></el-radio-button>
              <el-radio-button label="￥10000">1年 <br/><span class="money">￥12000</span></el-radio-button>
            </el-radio-group>
          </div>
          <div style="color:#fff;fontSize: 16px;">支付方式</div>
            <el-radio-group v-model="radio" style="marginTop: 10px;">
              <el-radio-button label="微信支付"></el-radio-button>
            </el-radio-group>
          <div class="payMoney">应付金额：<span>{{radio1}}</span></div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="paybutton">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <login :dialog-login="dialogLogin" @login="login" />
  </div>
</template>
<script>
import { toggleMessage } from "@/utils/common"
import login from '@/components/login'
import { wxhook,createSubscription } from '@/api/subscription'
export default {
  name: "",
   data() {
      return {
        dialogVisible: false,
        dialogLogin: false,
        tableData: [{
          date: '2',
          name: '小虎',
          address: '1518 弄'
        }, {
          date: '1',
          name: '小虎',
          address: '1517 弄'
        }],
        radio1: '',
        radio: '微信支付'
      }
    },
  components: {
    login
  },
  created() {},
  mounted() {},
  methods: {
    payWindow() {
      if (!this.$store.state.token) {
        this.dialogLogin = true
        toggleMessage('请登录','Please login')
        return
      }
      this.dialogVisible = true
    },
    login() {
      this.dialogLogin = false
    },
    async paybutton() {
      if (this.radio1 === '') {
        toggleMessage('请选择付款金额','Please select the amount of payment')
        return
      }
      const data = await createSubscription({
        subscriptionId: 'faac456a4511276ae338cf',
        productKey: 'Halo-Studio',
        paymentMethod: {
          type: 'wxpay'
        },
        payRule: {
          price: 200,
          cycle: 30
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
  .halo-studio {
    background-image: linear-gradient(to right, #6e2934, #2d0f23);
    text-align: center;
    padding-top: 150px;
    color: #ffffff;
    overflow: hidden;
    .title {
      margin: 0;
    }
    .card {
      width: 300px;
      padding: 20px 30px;
      box-sizing: border-box;
      background-color: #fff;
      margin: 50px auto 100px;
      border-radius: 10px;
      color: #000;
      .card-title {
        margin: 0;
        margin-bottom: 10px;
      }
      .card-description {
        font-size: 12px;
        margin-bottom: 15px;
      }
      .card-button {
        width: 60%;
        background-color: #ffc322;
        margin: 5px auto 0;
        padding: 10px 0;
        font-size: 14px;
        border-radius: 34px;
        color: #fff;
        cursor: pointer;
      }
    }
    .table {
      width: 20%;
      margin: 0 auto 150px;
      border-radius: 5px
    }

  }
</style>
<style lang="scss">
  .halo-studio {
    .el-dialog {
      background-color: #333333;
      color: #fff;
      .el-dialog__title {
        color: #fff;
      }
      .el-radio-group {
        margin-bottom: 10px;
        .el-radio-button {
          margin-right: 30px;
          .el-radio-button__inner {
            border-radius: 5px;
          }
        }
      }
      .payMoney {
        font-size: 16px;
        color: #fff;
        span {
          color: #f59a23;
        }
      }
      .el-dialog__footer {
        .el-button {
          background-color: #169bd5;
          width: 70%;
        }
      }
    }
  }
</style>